import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import ContactBlock from 'components/ContactBlock'
import ImageWrap from 'components/ImageWrap'
import { strings } from 'js/strings'

export default function ContactTemplate({ data }) {
  const page = data.wpPage
  const contactBlockMid = page.contactBlockMid
  const { locationsGrid, singleImage } = page.template.contact

  const contactBlockCustom = {
    image: contactBlockMid.imageMid,
    copy: contactBlockMid.copyMid,
    hubspotForm: contactBlockMid.hubspotFormMid,
  }
  return (
    <Layout
      bodyClassName='page-template page-template-page-builder page-builder page page-id-130 page-parent contact-us app-data index-data singular-data page-data page-130-data page-contact-us-data page-builder-data'
      page={page}
    >
      <ContactBlock content={contactBlockCustom} />
      <GridLocations content={locationsGrid} />
      <SingleImage content={singleImage} />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      ...SeoPage
      ...BannerPage
      ...ContactBlockMidPage
      ...RelatedBlockPage
      template {
        templateName
        ... on WpTemplate_Contact {
          contact {
            locationsGrid {
              region
              locations {
                image {
                  ...GatsbyImage
                }
                local
                area
                company
                address
                phone
              }
            }
            singleImage {
              image {
                ...GatsbyImage
              }
            }
          }
        }
      }
    }
  }
`

const GridLocations = ({ content }) => {
  return (
    <section className='locations-grid'>
      <div className='locations-grid__flex'>
        {content.map((region, i) => (
          <GridLocationsRegion key={i} content={region} />
        ))}
      </div>
      <div className='locations-grid__hypercube'></div>
    </section>
  )
}

const GridLocationsRegion = ({ content }) => {
  const { region, locations } = content
  return (
    <div className='locations-grid__col'>
      <div className='locations-grid__region'>
        <h1 className='locations-grid__region__title'>
          <p>{region}</p>
        </h1>
      </div>
      {locations.map((item, i) => (
        <GridLocationsRegionItem key={i} content={item} />
      ))}
    </div>
  )
}

const GridLocationsRegionItem = ({ content }) => {
  const { image, local, area, company, address, phone } = content
  let toEmail = 'hello@analyticpartners.com'
  if (area === '中国') toEmail = 'China@analyticpartners.com'
  return (
    <div className='location'>
      {image && <ImageWrap className='location__img' image={image} />}
      <div className='location__content'>
        <h6 className='location__small'>{local}&nbsp;</h6>
        <h3 className='location__large '>{area}</h3>
        <p className='location__name'>{company}</p>
        <p className='location__address'>{address}</p>
        {phone && (
          <p className='location__phone-number'>
            {strings.tel}
            {phone}
          </p>
        )}
      </div>
      <a href={`mailto:${toEmail}`} className='location__link' target='"blank"'>
        {strings.contact}
      </a>
    </div>
  )
}

const SingleImage = ({ content }) => {
  const { image } = content
  return (
    <section className='image'>
      <ImageWrap image={image} />
    </section>
  )
}
